import { CmsGuideLayout } from "../../components/CmsGuideLayout"
import { lorem } from "../../lib/lorem"

import * as richTextStyles from "../../slices/PageDataBodyRichText.module.css"
import * as typography from "../../styles/typography.module.css"

const Sample = (props: { label: string; children: React.ReactNode }) => {
	return (
		<div>
			<p
				style={{
					textTransform: "uppercase",
					fontWeight: 700,
					color: "#6f6f6f",
					fontFamily: "system-ui, sans-serif",
				}}
			>
				{props.label}
			</p>

			{props.children}
		</div>
	)
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<div style={{ display: "grid", gap: "2.5rem" }}>
				<Sample label="Heading 2">
					<h2 className={typography.heading2}>{lorem.sentences(1)}</h2>
				</Sample>

				<Sample label="Heading 3">
					<h3 className={typography.heading3}>{lorem.sentences(1)}</h3>
				</Sample>

				<Sample label="Heading 4">
					<h4 className={typography.heading4}>{lorem.sentences(1)}</h4>
				</Sample>

				<Sample label="Heading 5">
					<h5 className={typography.heading5}>{lorem.sentences(1)}</h5>
				</Sample>

				<Sample label="Paragraph">
					<p className={typography.paragraph1}>{lorem.paragraphs(1)}</p>
				</Sample>

				<Sample label="Small Paragraph">
					<p className={typography.paragraph2}>{lorem.paragraphs(1)}</p>
				</Sample>

				<Sample label="Hyperlink">
					<a href="#" className={richTextStyles.link}>
						<p className={typography.paragraph1}>{lorem.words(3)}</p>
					</a>
				</Sample>

				<Sample label="List">
					<ul className={typography.list}>
						<li className={typography.listItem} data-color="yellow">
							{lorem.sentences(1)}
						</li>
						<li className={typography.listItem} data-color="yellow">
							{lorem.sentences(1)}
						</li>
						<li className={typography.listItem} data-color="yellow">
							{lorem.sentences(1)}
						</li>
					</ul>
				</Sample>

				<Sample label="Ordered List">
					<ul className={typography.orderedList}>
						<li className={typography.orderedListItem} data-color="yellow">
							{lorem.sentences(1)}
						</li>
						<li className={typography.orderedListItem} data-color="yellow">
							{lorem.sentences(1)}
						</li>
						<li className={typography.orderedListItem} data-color="yellow">
							{lorem.sentences(1)}
						</li>
					</ul>
				</Sample>
			</div>
		</CmsGuideLayout>
	)
}

export default Component
